import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React, { useContext } from "react";
import { TabPanel, ResultPanel } from "./panel";
import { lightBlue } from "@mui/material/colors";
import PanelRender from "../search/search";
import ResultPanelRenderBox from "../box/box";
import ResultPanelRenderItem from "../item/item";
import { Context } from "../../context/app_context";
import PickingListCards from "../picking_list/picking_list_cards";

const styleTabs = {
  minWidth: "12rem",
  color: "black",
  backgroundColor: "white",
  "&.Mui-selected": {
    backgroundColor: lightBlue[50],
  },
};

export default function DashboardTabs() {
  const { currentTab, setCurrentTab } = useContext(Context);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Tabs onChange={handleChange} value={currentTab}>
        <Tab label="Picking Lists" data-cy="picking_tab" sx={styleTabs} />
        <Tab label="Box" data-cy="box_tab" sx={styleTabs} />
        <Tab label="Item" data-cy="item_tab" sx={styleTabs} />
      </Tabs>

      <TabPanel>
        <PanelRender />
      </TabPanel>

      <ResultPanel>
        <PickingListCards />
        <ResultPanelRenderBox />
        <ResultPanelRenderItem />
      </ResultPanel>
    </>
  );
}
