import React, { useContext } from "react";
import { Context } from "../../context/app_context";
import SearchForm from "./form";

export default function PanelRender() {
  const { currentTab } = useContext(Context);
  if (currentTab >= 1) {
    return <SearchForm currentTab={currentTab}></SearchForm>;
  }
  return <></>;
}
