import styled from "@emotion/styled";

const PicingListWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
`;

export default PicingListWrapper;
