import axios from "axios";
import middleware from "../middleware";
import { baseURL } from "../config";
import { validateRequest } from "../../common/validate_request";

export const getPickingListForPicking = async () => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios
      .get(`${baseURL}/picking_list/get_pickintg_list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: validateRequest,
      })
  );
  return response;
};
export const getPickingListSalesOrders = async (listId: number) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(
      `${baseURL}/picking_list/get_pickintg_list_sales_orders/${listId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: validateRequest,
      }
    )
  );
  return response;
};
