import React, { useState } from "react";
import { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import PickingListCard, { PickingList } from "./card";
import { Context } from "../../context/app_context";
import { componentServiceConsumer } from "../../common/servicve_consumer";
import { getPickingListForPicking } from "../../services/picking_list/picking_list";
import Loading from "../loading/loading";
import PicingListWrapper from "./wrapper";
import SalesOrdersDialog from "./sales_orders_dialog";
import SalesOrdersExternalDialog from "./sales_orders_external_dialog";

export default function PickingListCards() {
  const { setLoading, currentTab } = useContext(Context);
  const [pickingLists, setPickingLists] = useState<PickingList[]>([]);
  const [currentList, setCurrentList] = useState<PickingList>();
  const [showSalesOrdersDialog, setShowSalesOrderDialog] =
    useState<boolean>(false);
  const [showSalesOrdersExternalDialog, setShowSalesOrderExternalDialog] =
    useState<boolean>(false);
  const [showSalesOrdersErrorDialog, setShowSalesOrderErrorDialog] =
    useState<boolean>(false);

  const loadListsforPicking = async () => {
    setLoading(true);
    const serviceResponse = await componentServiceConsumer(
      getPickingListForPicking,
      undefined,
      "Error Loading Picking List: "
    );

    setPickingLists(serviceResponse?.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    loadListsforPicking();
  }, []);
  useEffect(() => {
    if (
      !showSalesOrdersDialog &&
      !showSalesOrdersExternalDialog &&
      !showSalesOrdersErrorDialog
    ) {
      loadListsforPicking();
    }
  }, [
    showSalesOrdersDialog,
    showSalesOrdersExternalDialog,
    showSalesOrdersErrorDialog,
  ]);

  if (currentTab == 0) {
    return (
      <>
        <Loading />
        <PicingListWrapper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "2rem",
              flexWrap: "wrap",
            }}
          >
            {pickingLists
              .sort((a: PickingList, b: PickingList) => a.sort - b.sort)
              .map((list: PickingList) => {
                return (
                  <PickingListCard
                    key={`id_list_${list.id}`}
                    list={list}
                    onClick={() => {
                      setCurrentList(list);
                      if (list.isExternal) {
                        setShowSalesOrderExternalDialog(true);
                      } else if (list.isError) {
                        setShowSalesOrderErrorDialog(true);
                      } else {
                        setShowSalesOrderDialog(true);
                      }
                    }}
                  />
                );
              })}
          </Box>
        </PicingListWrapper>
        <SalesOrdersDialog
          open={showSalesOrdersDialog}
          setOpen={setShowSalesOrderDialog}
          currentList={currentList}
        />
        <SalesOrdersExternalDialog
          showSearch={true}
          open={showSalesOrdersExternalDialog}
          setOpen={setShowSalesOrderExternalDialog}
          currentList={currentList}
        />
        <SalesOrdersExternalDialog
          showSearch={false}
          open={showSalesOrdersErrorDialog}
          setOpen={setShowSalesOrderErrorDialog}
          currentList={currentList}
        />
      </>
    );
  }
  return <></>;
}
