import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactElement } from "react";

interface DecisionDialogProps {
  open: boolean;
  setOpen: (openState: boolean) => void;
  handleDecision: () => void;
  title: ReactElement;
  content: ReactElement;
  button1Text: string;
  button2Text: string;
}

export default function DecisionDialog({
  title,
  content,
  button1Text,
  button2Text,
  open,
  setOpen,
  handleDecision,
}: DecisionDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            handleDecision();
            setOpen(false);
          }}
        >
          {button1Text}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpen(false)}
        >
          {button2Text}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
