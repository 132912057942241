import React from "react";
import "./App.css";
import MainRoutes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./config/theme";
import { AppContextProvider } from "./context/app_context";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <>
            <MainRoutes />
            <ToastContainer
              position="top-right"
              limit={3}
              autoClose={5000}
              pauseOnHover
              closeOnClick
            />
          </>
        </AppContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
