import React, { useContext } from "react";
import styled from "@emotion/styled";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "./loginValidationSchema";
import InputText from "../inputs/InputText";
import Button from "@mui/material/Button";
import { login } from "../../services/auth/auth";
import { toast } from "react-toastify";
import { ErrorMessage } from "../error/error";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/app_context";
import { getUserNameFromToken } from "../../common/get_user_name";

const FormWrapper = styled.div`
  padding: 1rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: #ffffff;
  height: 20rem;
  border-radius: 10px;
`;

export default function LoginForm() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginValidationSchema) });
  const navigate = useNavigate();

  const { setCurrentUserName } = useContext(Context);

  const onSubmit = async (data: FieldValues) => {
    const loginResponse = await login(data);
    if (loginResponse?.status != 200) {
      toast.error(
        <ErrorMessage testId="login_error">
          {loginResponse?.data.message}
        </ErrorMessage>
      );
    } else {
      localStorage.setItem("auth-token", loginResponse.data.jwt);
      setCurrentUserName(getUserNameFromToken());
      navigate("/");
    }
  };

  return (
    <>
      <FormWrapper>
        <InputText
          id={"username"}
          label={"Username"}
          control={control}
          errors={errors}
          required={false}
          testId="username"
        />
        <InputText
          id={"password"}
          label={"Password"}
          type="password"
          control={control}
          errors={errors}
          required={false}
          testId="password"
        />

        <Button
          color="primary"
          variant="contained"
          sx={{ borderRadius: 100, color: "white", paddingX: "4rem" }}
          onClick={handleSubmit(onSubmit)}
          data-cy="login_submit"
        >
          Login
        </Button>
      </FormWrapper>
    </>
  );
}
