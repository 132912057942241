import React, { useContext } from "react";
import styled from "@emotion/styled";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { searchValidationSchema } from "./searchValidationSchema";
import InputText from "../inputs/InputText";
import Button from "@mui/material/Button";
import { Context } from "../../context/app_context";
import Loading from "../loading/loading";
import { getBox } from "../../services/box/box";
import { toast } from "react-toastify";
import { ErrorMessage } from "../error/error";
import { BoxItem } from "../../entities/box_items.entity";
import { getItem } from "../../services/item/item";
import { Item } from "../../entities/item.entity";

const SearchWrapper = styled.div`
  width: 100%;
  height: 6rem;
  margin-top: 1rem;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export default function SearchForm({ currentTab }: { currentTab: number }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(searchValidationSchema) });

  const { setBox, setItem, setLoading, setItemSearchExternal } =
    useContext(Context);

  const searchBox = async (data: FieldValues) => {
    const searchResponse = await getBox(data);
    if (searchResponse.status != 200) {
      toast.error(
        <ErrorMessage testId="search_box_error">
          {searchResponse.data.message}
        </ErrorMessage>
      );
    } else {
      await setBox(searchResponse.data);
      reset({ barcode: "" }, { keepValues: true });
    }
  };
  const searchSalesOrdersExternal = async (data: FieldValues) => {
    await setItemSearchExternal(data.barcode);
    reset({ barcode: "" }, { keepValues: true });
  };

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    if (currentTab === 1) {
      await searchBox(data);
    }

    if (currentTab === 2) {
      const searchResponse = await getItem(data);
      if (searchResponse.status != 200) {
        toast.error(
          <ErrorMessage testId="search_item_error">
            {searchResponse.data.message}
          </ErrorMessage>
        );
      } else {
        const item: Item = searchResponse.data;
        await setItem(item);
        if (item && item.box_items && item.box_items.length > 0) {
          const box_item = searchResponse.data.box_items.find(
            (box_item: BoxItem) => box_item.box.is_extra_location === false
          );
          if (box_item) {
            await searchBox({
              barcode: box_item.box.id,
            });
          }
        } else {
          toast.error(
            <ErrorMessage testId="error_location_not_found">
              {"Item has not location"}
            </ErrorMessage>
          );
        }
      }
    }

    if (currentTab == 0) {
      await searchSalesOrdersExternal(data);
    }

    reset({ barcode: "" });
    setLoading(false);
  };

  return (
    <>
      <Loading />
      <SearchWrapper>
        <InputText
          sx={{
            minWidth: "17rem",
            mt: 1,
          }}
          id={"barcode"}
          label={"Barcode"}
          control={control}
          errors={errors}
          required={false}
          testId="barcode"
          autoFocus={true}
          InputProps={{
            onKeyDown: (event: KeyboardEvent) => {
              if (event.key === "Enter") {
                handleSubmit(onSubmit)();
              }
            },
          }}
        />

        <Button
          color="primary"
          variant="contained"
          sx={{ borderRadius: 100, color: "white", paddingX: "4rem" }}
          onClick={handleSubmit(onSubmit)}
          data-cy="search_submit"
        >
          Search
        </Button>
      </SearchWrapper>
    </>
  );
}
