import React, { MouseEventHandler } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export interface PickingList {
  id?: number;
  name: string;
  color: string;
  separatorCount?: number;
  searchType: string;
  searchValue: string;
  isDefault?: boolean;
  isExternal?: boolean;
  isError?: boolean;
  sort: number;
  salesOrdersCount?: number;
}

export default function PickingListCard({
  list,
  onClick,
}: {
  list: PickingList;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          boxShadow: "4px 4px 10px 0px #00000032;",
          borderRadius: "1rem",
          border: `2px solid ${list.color}`,
          padding: "2rem",
          minWidth: "14rem",
        }}
        onClick={onClick}
      >
        <Typography variant="h4">{list.name}</Typography>
        <Typography variant="body1" sx={{ color: list.color }}>
          {list.color}
        </Typography>
        <Typography variant="h1">{list.salesOrdersCount}</Typography>
      </Box>
    </>
  );
}
