import React, { useContext } from "react";
import { Context } from "../../context/app_context";
import ItemInfo from "./item_info";

export default function ResultPanelRenderItem() {
  const { currentTab } = useContext(Context);

  if (currentTab === 2) {
    return (
      <>
        <ItemInfo />
      </>
    );
  }
  return <></>;
}
