import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/app_context";
import {
  ItemImage,
  ItemImageWrapper,
  ItemInfoWrapper,
  TextWrapper,
} from "./info_components";
import { BoxItem } from "../../entities/box_items.entity";
import { format } from "date-fns";
import { imageUrl } from "../../common/image_url";

export default function BoxInfo() {
  const { item } = useContext(Context);
  const [mainLocation, setMainLocation] = useState<BoxItem | undefined>();
  const [extraLocation, setExtraLocation] = useState<string | undefined>();

  useEffect(() => {
    if (item && item.box_items && item.box_items.length > 0) {
      const mainLocationFound = item.box_items.find(
        (box_item) =>
          box_item.box.is_extra_location === false &&
          !box_item.box.location.code.includes("999")
      );
      setMainLocation(mainLocationFound);
      const extraLocationFound = item.box_items
        .filter((box_item) => box_item.box.is_extra_location === true)
        .reduce((acc, boxExtra) => {
          return `${acc ? `${acc},` : ""}${boxExtra.box.location.code}`;
        }, "");
      setExtraLocation(extraLocationFound);
    }
  }, [item]);

  if (item) {
    return (
      <>
        <ItemInfoWrapper>
          <Typography variant="h5">Item {item.id}</Typography>
          <TextWrapper>{/* EMPTY */}</TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Main Location:</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {mainLocation ? mainLocation.box.location.code : "N/A"}
            </Typography>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Extra Location:</Typography>
            <Typography variant="body1" fontWeight={"bold"} textAlign="left">
              {extraLocation ? extraLocation : "N/A"}
            </Typography>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Updated By:</Typography>
            <Typography variant="body1" fontWeight={"bold"}>
              {mainLocation ? mainLocation.user_last_update : "N/A"}
            </Typography>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Last Update:</Typography>
            <Typography variant="body1" fontWeight={"bold"} textAlign="left">
              {mainLocation
                ? format(new Date(mainLocation.updated_at), "MM/dd/yyyy")
                : "N/A"}
            </Typography>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Description:</Typography>
            <Typography variant="body1" fontWeight={"bold"} textAlign="left">
              {`${item.brand} ${item.reference} ${item.color.value_eng}`}
            </Typography>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Size:</Typography>
            <Typography variant="body1" fontWeight={"bold"} textAlign="left">
              {item.size?.id || "N/A"}
            </Typography>
          </TextWrapper>
          <TextWrapper>
            <Typography variant="body1">Reorder Point:</Typography>
            <Typography variant="body1" fontWeight={"bold"} textAlign="left">
              {item.reorder_point ?? "N/A"}
            </Typography>
          </TextWrapper>

          <ItemImageWrapper>
            <ItemImage src={imageUrl(item.image_url)}></ItemImage>
          </ItemImageWrapper>
        </ItemInfoWrapper>
      </>
    );
  } else {
    return <></>;
  }
}
