import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Header, { HeaderUser } from "../components/header/header";
import { IconButton, Typography } from "@mui/material";
import { MainWrapper, ContentWrapper } from "./wrapper";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { Context } from "../context/app_context";
import { logout } from "../services/auth/auth";
export default function MainLayout() {
  const { currentUserName } = useContext(Context);

  return (
    <>
      <MainWrapper>
        <Header>
          <Typography
            data-cy="header"
            sx={{
              fontFamily: "Roboto",
              fontSize: "2rem",
              color: "black",
              fontWeight: "400",
            }}
          >
            GDD Warehouse {process.env.REACT_APP_VERSION}
          </Typography>
          <HeaderUser>
            <Typography
              data-cy="header"
              sx={{
                fontFamily: "Roboto",
                fontSize: "1.2rem",
                color: "black",
                fontWeight: "400",
              }}
            >
              {currentUserName}
            </Typography>
            <IconButton
              onClick={async () => {
                await logout();
                localStorage.setItem("auth-token", "");
                location.href = "/";
              }}
            >
              <ArrowLeftOnRectangleIcon
                style={{ width: "50px", height: "50px" }}
              />
            </IconButton>
          </HeaderUser>
        </Header>
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </MainWrapper>
    </>
  );
}
