import { toast } from "react-toastify";

export async function componentServiceConsumer(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceFuncion: (data: any) => any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataforService: any,
  errorMessage: string
) {
  const serviceResponse = await serviceFuncion(dataforService);
  if (serviceResponse && serviceResponse.status == 200) {
    return serviceResponse.data;
  } else {
    toast.error(`${errorMessage} ${serviceResponse?.data?.message}`);
  }
}