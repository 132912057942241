import React, { useContext } from "react";
import { Context } from "../../context/app_context";
import BoxInfo from "./box_info";
import CreateNewBox from "./new_box";
import PrintLabels from "./print";
import DeleteBox from "./delete";
import BoxTable from "./table";

export default function ResultPanelRenderBox() {
  const { currentTab, box } = useContext(Context);

  if (currentTab === 1) {
    return (
      <>
        <CreateNewBox />
        <BoxInfo />
        <BoxTable />
        {box?.id != undefined &&
        box.box_items?.filter((x) => x.item).length > 0 ? (
          <PrintLabels boxId={box.id} />
        ) : (
          <></>
        )}
        {box?.id != undefined &&
        (!box.box_items || box.box_items?.filter((x) => x.item).length <= 0) ? (
          <DeleteBox boxId={box.id} />
        ) : (
          <></>
        )}
      </>
    );
  }
  return <></>;
}
