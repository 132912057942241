import React, { useContext, useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import FullScreenDialog from "./full_dialog";
import { PickingList } from "./card";
import {
  Typography,
  List,
  ListItemButton,
  Divider,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  TextField,
} from "@mui/material";
import { Context } from "../../context/app_context";
import { componentServiceConsumer } from "../../common/servicve_consumer";
import Loading from "../loading/loading";
import {
  getSoItemExtraLocations,
  getSOItemLocation,
} from "../../common/item_location";
import {
  asignSeparatorToSalesOrder,
  getSalesOrderDetail,
  savePickingDetailMessage,
  saveSalesOrderPicked,
  saveSalesOrderPicking,
} from "../../services/sales_orders/sales_orders";
import DecisionDialog from "./dialog";
import { toast } from "react-toastify";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const colors: any = {
  "": "",
  OK: "#d4edda",
  "Not enough inventory found": "#f8d7da",
  "External Warehouse": "#fff3cd",
};

export default function SalesOrdersDetailDialog({
  open,
  setOpen,
  currentList,
  currentSalesOrder,
  setUpperOpen,
  isExternal,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setUpperOpen: Dispatch<SetStateAction<boolean>>;
  currentList?: PickingList;
  currentSalesOrder: any;
  isExternal: boolean;
}) {
  const { loading, setLoading } = useContext(Context);
  const [salesOrder, setSalesOrder] = useState<any>();
  const [salesOrderDetail, setSalesOrderDetail] = useState<any>();
  const [showPickingDialog, setShowPickingDialog] = useState<boolean>(false);
  const [showFinishPicking, setShowFinishPicking] = useState<boolean>(false);
  const [currentPickingMessage, setCurrentPickginMessage] =
    useState<string>("");
  const [currentSeparatorBarcode, setCurrentSeparatorBarcode] =
    useState<string>("");

  const loadCurrentSalesOrderDetail = async () => {
    setLoading(true);
    const serviceResponse = await componentServiceConsumer(
      getSalesOrderDetail,
      currentSalesOrder?.SO_Number,
      "Error Loading Sales Orders: "
    );
    if (serviceResponse) {
      if (!isExternal) {
        const servicePickingresponse = await componentServiceConsumer(
          saveSalesOrderPicking,
          {
            orderId: currentSalesOrder.id,
          },
          "Error: "
        );

        if (!servicePickingresponse) {
          setLoading(false);
          setOpen(false);
          setUpperOpen(false);
          return;
        }
      }

      const dataResponse = serviceResponse.data;
      dataResponse.SalesOrdersItems.forEach((x: any) => {
        if (x.picking_message) {
          x.color = colors[x.picking_message] ?? "#d6d8d9";
        }
      });

      setSalesOrder(dataResponse);
    }

    setLoading(false);
  };

  const savePickingDetail = async () => {
    const detail = salesOrder.SalesOrdersItems.find(
      (x: any) => x.id == salesOrderDetail.id
    );
    detail.color = colors[currentPickingMessage];

    const serviceResponse = await componentServiceConsumer(
      savePickingDetailMessage,
      {
        order_detail_id: salesOrderDetail.id,
        picking_detail: currentPickingMessage,
      },
      "Error:"
    );
    setCurrentPickginMessage("");
  };
  const finishPicking = async (asignSeparator: boolean) => {
    const orderPickingComplete: boolean = salesOrder.SalesOrdersItems.reduce(
      (acc: boolean, x: any) => !!x.color && acc,
      true
    );
    if (asignSeparator) {
      const separatorResponse = await componentServiceConsumer(
        asignSeparatorToSalesOrder,
        {
          order_number: salesOrder.SO_Number,
          separator_number: salesOrder?.separator
            ? salesOrder?.separator?.barcode
            : currentSeparatorBarcode,
          color: salesOrder?.separator
            ? salesOrder?.separator?.color
            : currentList?.color,
        },
        "Error:"
      );
      if (orderPickingComplete && separatorResponse.statusCode == 200) {
        const salesOrderPickedResponse = await componentServiceConsumer(
          saveSalesOrderPicked,
          {
            orderId: salesOrder.id,
          },
          "Error:"
        );
        if (salesOrderPickedResponse.statusCode == 200) {
          toast.success("Sales Order Picking saved successfully");
          setShowFinishPicking(false);
          setOpen(false);
          setUpperOpen(false);
        }
      } else {
        toast.success("Sales Order Separator saved successfully");
        setShowFinishPicking(false);
        setOpen(false);
        setUpperOpen(false);
      }
    } else {
      if (orderPickingComplete) {
        const salesOrderPickedResponse = await componentServiceConsumer(
          saveSalesOrderPicked,
          {
            orderId: salesOrder.id,
          },
          "Error:"
        );
        if (salesOrderPickedResponse.statusCode == 200) {
          toast.success("Sales Order Picking saved successfully");
          setShowFinishPicking(false);
          setOpen(false);
          setUpperOpen(false);
        }
      } else {
        toast.success("Sales Order Separator saved successfully");
        setShowFinishPicking(false);
        setOpen(false);
        setUpperOpen(false);
      }
    }

    setCurrentSeparatorBarcode("");
  };

  useEffect(() => {
    if (currentSalesOrder) loadCurrentSalesOrderDetail();
  }, [currentSalesOrder]);

  useEffect(() => {
    if (!open) setSalesOrder(undefined);
  }, [open]);

  return (
    <>
      <FullScreenDialog
        open={open}
        setOpen={setOpen}
        color={
          salesOrder?.separator
            ? salesOrder?.separator?.color
            : currentList?.color
        }
        title={
          salesOrder?.separator
            ? `${currentSalesOrder?.SO_Number} - ${salesOrder?.separator?.barcode}`
            : currentSalesOrder?.SO_Number
        }
      >
        <>
          <Loading />
          <List>
            <ListItemButton>
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 4fr 1fr",
                  padding: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">Location</Typography>
                <Typography variant="body1">Description</Typography>
                <Typography variant="body1">QTY:(Disp)</Typography>
              </Box>
            </ListItemButton>
            <Divider />
            {loading ? (
              <></>
            ) : (
              salesOrder?.SalesOrdersItems.map(
                (soDetail: any, index: number) => {
                  return (
                    <>
                      <ListItemButton
                        key={`index_${index}`}
                        sx={{ background: soDetail?.color ?? "transparent" }}
                        onClick={() => {
                          setSalesOrderDetail(soDetail);
                          setShowPickingDialog(true);
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "grid",
                            gridTemplateColumns: "1fr 4fr 1fr",
                            padding: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "18px", fontWeight: "800" }}
                          >
                            {getSOItemLocation(soDetail)}
                          </Typography>
                          <Typography variant="body1">
                            {`${soDetail.Item.brand} ${soDetail.Item.reference} ${soDetail.Item.color.color} `}
                            <strong>{soDetail.Item.size.id}</strong>
                          </Typography>
                          <Typography variant="body1">
                            {`${soDetail.Quantity}:(${soDetail.Item.quantity_on_hand})`}
                          </Typography>
                        </Box>
                      </ListItemButton>
                      <Divider />
                    </>
                  );
                }
              )
            )}
          </List>

          <Button
            color="primary"
            variant="contained"
            sx={{
              alignSelf: "center",
              marginTop: "2rem",
              borderRadius: 100,
              color: "white",
              paddingX: "4rem",
            }}
            onClick={() => {
              if (salesOrder?.separator) {
                finishPicking(false);
              } else if (
                salesOrder.SalesOrdersItems.reduce(
                  (acc: boolean, x: any) =>
                    x.color === colors["External Warehouse"] && acc,
                  true
                )
              ) {
                finishPicking(false);
              } else {
                setShowFinishPicking(true);
              }
            }}
          >
            Finish Picking
          </Button>

          <DecisionDialog
            title={
              <>
                {`${salesOrderDetail?.Item.brand} ${salesOrderDetail?.Item.reference} ${salesOrderDetail?.Item.color.color}`}
                <span style={{ fontSize: "32px", marginLeft: "20px" }}>
                  {""}
                  {salesOrderDetail?.Item.size.id}
                </span>
              </>
            }
            content={
              <>
                <Box sx={{ border: `` }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      alignItems: "center",
                      minWidth: "490px",
                    }}
                  >
                    <Typography variant="body1">Location</Typography>
                    <Typography
                      sx={{
                        justifySelf: "flex-end",
                        fontSize: "20px",
                        fontWeight: 800,
                      }}
                      variant="body1"
                    >
                      {getSOItemLocation(salesOrderDetail)}
                    </Typography>
                    <Typography variant="body1">Extra Locations</Typography>
                    <Typography
                      sx={{ justifySelf: "flex-end" }}
                      variant="body1"
                    >
                      {getSoItemExtraLocations(salesOrderDetail)}
                    </Typography>

                    <Typography variant="body1">QTY:(Disp)</Typography>
                    <Typography
                      sx={{
                        justifySelf: "flex-end",
                        color: salesOrderDetail?.Quantity > 1 ? "red" : "black",
                      }}
                      variant="h4"
                    >
                      {`${salesOrderDetail?.Quantity}:(${salesOrderDetail?.Item.quantity_on_hand})`}
                    </Typography>

                    <Typography variant="body1">Current Message</Typography>
                    <Typography
                      sx={{ justifySelf: "flex-end" }}
                      variant="body1"
                    >
                      {salesOrderDetail?.picking_message}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ paddingLeft: "3rem" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    defaultChecked={true}
                    value={currentPickingMessage}
                    onChange={(e, val) => setCurrentPickginMessage(val)}
                  >
                    <FormControlLabel
                      value="OK"
                      control={<Radio />}
                      label="OK"
                    />
                    <FormControlLabel
                      value="Not enough inventory found"
                      control={<Radio />}
                      label="Not enough inventory found"
                    />
                    <FormControlLabel
                      value="External Warehouse"
                      control={<Radio />}
                      label="External Warehouse"
                    />
                  </RadioGroup>
                </Box>
              </>
            }
            button1Text={"CONFIRM"}
            button2Text={"CANCEL"}
            open={showPickingDialog}
            setOpen={() => setShowPickingDialog(false)}
            handleDecision={() => savePickingDetail()}
          />
          <DecisionDialog
            title={<>Comfirm Picking</>}
            content={
              <>
                <Typography variant="body1">
                  Set separator for sales order
                </Typography>
                <Box mt={3} sx={{ border: `` }}>
                  <TextField
                    label="Separator"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        finishPicking(true);
                      }
                    }}
                    autoFocus
                    onChange={(e) => setCurrentSeparatorBarcode(e.target.value)}
                    value={currentSeparatorBarcode}
                  />
                </Box>
              </>
            }
            button1Text={"FINISH"}
            button2Text={"CANCEL"}
            open={showFinishPicking}
            setOpen={() => setShowFinishPicking(false)}
            handleDecision={() => finishPicking(true)}
          />
        </>
      </FullScreenDialog>
    </>
  );
}
