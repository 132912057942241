import React from "react";
import DashboardTabs from "../components/dashboard/tabs";
import TabsWrapper from "../components/dashboard/wrapper";

export default function Dashboard() {
  return (
    <>
      <TabsWrapper>
        <DashboardTabs></DashboardTabs>
      </TabsWrapper>
    </>
  );
}
