import { Buffer } from "buffer";
import { v4 } from "uuid";

export function getUserNameFromToken() {
  const token = localStorage.getItem("auth-token");
  if (token) {
    const splitedToken = token.split(".");
    if (splitedToken.length > 1) {
      const decoded = Buffer.from(splitedToken[1], "base64").toString("utf-8");
      try {
        const jsonDecoded = JSON.parse(decoded);
        return jsonDecoded.name;
      } catch (error) {
        console.log(error);
      }
    }
  }
  return "";
}

export function getCurrentDeviceToken() {
  let deviceToken = localStorage.getItem("device-token");
  if (!deviceToken) {
    deviceToken = v4();
    localStorage.setItem("device-token", deviceToken);
  }

  return deviceToken;
}
