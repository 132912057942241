import { Item } from "../entities/item.entity";

export const getItemLocation = (item: Item) => {
  if (item) {
    const boxItem = item.box_items.find(
      (i) =>
        i.box.is_extra_location === false &&
        !i.box.location.code.includes("999")
    );
    const location = boxItem?.box.location;
    if (location) {
      const { code } = location;
      return `${code}`;
    }
  }

  return "999-01-01";
};

export const getSOItemLocation = (salesOrderItem: any) => {
  if (salesOrderItem) {
    const boxItem = salesOrderItem.Item.box_items.find(
      (i: any) =>
        i.box.is_extra_location === false &&
        !i.box.location.code.includes("999")
    );
    const location = boxItem?.box.location;
    if (location) {
      const { code } = location;
      return `${code}`;
    }
  }

  return "999-01-01 - 0";
};

export const getSoItemExtraLocations = (SalesOrderItem: any) => {
  const item = SalesOrderItem?.Item ?? null;
  if (!item) return "";
  const extraLocationFound = item.box_items
    .filter((box_item: any) => box_item.box.is_extra_location === true)
    .reduce((acc: number, boxExtra: any) => {
      return `${acc ? `${acc},` : ""}${boxExtra.box.location.code}`;
    }, "");
  return extraLocationFound;
};
