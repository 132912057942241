import axios from "axios";
import middleware from "../middleware";
import { baseURL } from "../config";
import { getCurrentDeviceToken } from "../../common/get_user_name";

export const login = async (data: any) => {
  const response: any = await middleware(
    axios.post(`${baseURL}/auth/login`, data, {
      headers: {
        ["device-token"]: getCurrentDeviceToken(),
      },
    })
  );
  return response;
};
export const logout = async () => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(`${baseURL}/auth/logout`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response;
};
export const me = async () => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(`${baseURL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  return response.data;
};
